@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles.css */
@font-face {
  font-family: "Inter";
  font-style: normal;
  src: url("../public/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  src: url("../public/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  src: url("../public/fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  /* Gunakan 900 untuk "black" */
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  src: url("../public/fonts/Inter-Light.otf") format("truetype");
  font-weight: 300;
  /* Gunakan 300 untuk "light" */
}

@font-face {
  font-family: "Inter";
  font-style: 'normal';
  src: url("../public/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  /* Gunakan 500 untuk "medium" */
}

@font-face {
  font-family: "Inter";
  font-style: semibold;
  src: url("../public/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 9px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#f70505 0%, #8f0808 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #8f0808;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f0808;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #8f0808;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f0808;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-placeholder-color::placeholder {
  color: #ffffff88;
}

.input-placeholder-color:-ms-input-placeholder {
  color: #ffffff88;
}

.input-placeholder-color::-ms-input-placeholder {
  color: #ffffff88;
}


#imageSwiperSlider .swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

#imageSwiperSlider .swiper-wrapper {
  transform-style: preserve-3d;
}

#imageSwiperSlider .imagesliderSwiper {
  width: 100%;
  height: 100%;
}

#imageSwiperSlider .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  object-fit: contain;
}


#imageSwiperSlider .swiper-slide-duplicate-active,
#imageSwiperSlider .swiper-slide-duplicate-next,
#imageSwiperSlider .swiper-slide-next,
#imageSwiperSlider .swiper-slide-duplicate-prev,
#imageSwiperSlider .swiper-slide-prev,
#imageSwiperSlider .swiper-slide-duplicate img {
  opacity: 0.3;
}

#imageSwiperSlider .swiper-slide-visible.swiper-slide-active img {
  opacity: 1 !important;
}

#imageSwiperSlider .swiper-pagination {
  display: flex;
  flex: end;
  justify-content: center;
  gap: 0.5em;
  position: absolute;
  inset-inline: 0;
  inset-block-end: 1.5em;
  z-index: 1;
  height: 0;
}

.images-slider.swiper-slide {
  opacity: 0.25;
}

.images-slider.swiper-slide-visible {
  opacity: 1;
}


@layer utilities {
  
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
  .paused {
    animation-play-state: paused;
  }
  .white-filter {
    @apply brightness-0 invert;
  }
}
